import React from 'react';
import PropTypes from 'prop-types';
import { Link, injectIntl, intlShape } from 'gatsby-plugin-intl';

import QuestionCircle from 'svg/question-circle.svg';
import CircleCheck from 'svg/check-circle-solid.svg';
import CircleX from 'svg/times-circle-solid.svg';
import Button from 'components/Button';
import Tooltip from 'components/Tooltip';
import './PricingPanel.module.css';

function PricingPanel({
  intl,
  url,
  headerTitle,
  headerSubtitle,
  backgroundColor,
  planCostTitle,
  planCostSubtitle,
  planDescription,
  planDescriptionTooltip,
  planFeatures,
  buttonLabel,
}) {
  return (
    <div styleName="pricing-panel" data-bg={backgroundColor}>
      <header>
        <h4 styleName="header-title">
          {intl.formatMessage({ id: headerTitle })}
        </h4>

        {headerSubtitle && (
          <span styleName="header-subtitle">
            {intl.formatMessage({ id: headerSubtitle })}
          </span>
        )}
      </header>

      <div styleName="content">
        <div styleName="plan-cost">
          <h3 styleName="plan-cost-title">
            {intl.formatMessage({ id: planCostTitle })}
          </h3>

          {planCostSubtitle && (
            <span styleName="plan-cost-subtitle">
              {intl.formatMessage({ id: planCostSubtitle })}
            </span>
          )}
        </div>

        <div styleName="plan-description">
          <span>
            {intl.formatMessage({ id: planDescription })}
          </span>

          {planDescriptionTooltip && (
            <Tooltip message={intl.formatMessage({ id: planDescriptionTooltip })}>
              <QuestionCircle />
            </Tooltip>
          )}
        </div>

        <div styleName="features">
          {planFeatures.map((feature) => (
            <div styleName="feature" data-included={feature.included} key={feature.text}>
              {feature.included ? (
                <span aria-label="Included">
                  <CircleCheck />
                </span>
              ) : (
                <span aria-label="Excluded">
                  <CircleX />
                </span>
              )}

              <strong>
                {intl.formatMessage({ id: feature.text })}

                {feature.soon && (
                  <span styleName="soon">
                    {intl.formatMessage({ id: 'common.coming-soon' })}
                  </span>
                )}
              </strong>
            </div>
          ))}
        </div>

        {url.startsWith('http') ? (
          <a href={url}>
            <Button data-fancy={backgroundColor === 'mint'}>
              {intl.formatMessage({ id: buttonLabel })}
            </Button>
          </a>
        ) : (
          <Link to={url}>
            <Button data-fancy={backgroundColor === 'mint'}>
              {intl.formatMessage({ id: buttonLabel })}
            </Button>
          </Link>
        )}
      </div>
    </div>
  );
}

PricingPanel.propTypes = {
  intl: intlShape.isRequired,
  url: PropTypes.string,
  headerTitle: PropTypes.string.isRequired,
  headerSubtitle: PropTypes.string,
  backgroundColor: PropTypes.oneOf(['purple', 'mint']),
  planCostTitle: PropTypes.string.isRequired,
  planCostSubtitle: PropTypes.string,
  planDescription: PropTypes.string.isRequired,
  planDescriptionTooltip: PropTypes.string,
  planFeatures: PropTypes.arrayOf(PropTypes.object).isRequired,
  buttonLabel: PropTypes.string.isRequired,
};

PricingPanel.defaultProps = {
  url: '',
  headerSubtitle: null,
  planCostSubtitle: null,
  backgroundColor: 'purple',
  planDescriptionTooltip: null,
};

export default injectIntl(PricingPanel);
