import React from 'react';
import PropTypes from 'prop-types';

import './Tooltip.module.css';

const Tooltip = ({ children, message, link }) => (
  <>
    {link && (
      <a href={link} target="_blank" rel="noreferrer noopener" aria-label="Tooltip link">
        <span
          styleName="tooltip"
          data-message={message}
          data-link="true"
          role="tooltip"
          aria-hidden="true"
        >
          <span />
          {children}
        </span>
      </a>
    )}
    {!link && (
      <span
        styleName="tooltip"
        data-message={message}
        role="tooltip"
        aria-hidden="true"
      >
        <span />
        {children}
      </span>
    )}
  </>
);

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  message: PropTypes.string.isRequired,
  link: PropTypes.string,
};

Tooltip.defaultProps = {
  link: undefined,
};

export default Tooltip;
