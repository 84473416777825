import React from 'react';
import PropTypes from 'prop-types';
import { Link, injectIntl, intlShape } from 'gatsby-plugin-intl';

import Container from 'components/Container';
import Button from 'components/Button';
import PricingPanel from './PricingPanel';
import './PricingPage.module.css';

const PlanShape = PropTypes.shape({
  url: PropTypes.string,
  id: PropTypes.string.isRequired,
  headerTitle: PropTypes.string.isRequired,
  headerSubtitle: PropTypes.string,
  backgroundColor: PropTypes.oneOf(['purple', 'mint']),
  planCostTitle: PropTypes.string.isRequired,
  planCostSubtitle: PropTypes.string,
  planDescription: PropTypes.string.isRequired,
  planDescriptionTooltip: PropTypes.string,
  planFeatures: PropTypes.arrayOf(PropTypes.object).isRequired,
  buttonLabel: PropTypes.string.isRequired,
});

const PricingContentShape = PropTypes.shape({
  plans: PropTypes.arrayOf(PlanShape),
});

const PricingPage = ({ intl, content: { plans } }) => (
  <div>
    <div styleName="pricing-panels-container">
      <Container>
        <h4 styleName="page-title">
          {intl.formatMessage({ id: 'pricing.title' })}
        </h4>

        <div styleName="pricing-panels">
          {plans.map((plan) => (
            <PricingPanel {...plan} key={plan.id} />
          ))}
        </div>
      </Container>
    </div>

    <Container>
      <div styleName="cta-message">
        <h4>
          {intl.formatMessage({ id: 'pricing.cta-title' })}
        </h4>

        <p>
          {intl.formatMessage({ id: 'pricing.cta-subtitle' })}
        </p>

        <Link to="/contact">
          <Button>
            {intl.formatMessage({ id: 'common.contact-us' })}
          </Button>
        </Link>
      </div>
    </Container>
  </div>
);

PricingPage.propTypes = {
  intl: intlShape.isRequired,
  content: PricingContentShape.isRequired,
};

export default injectIntl(PricingPage);
