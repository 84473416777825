/* eslint-disable react/prop-types */
import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import PricingPage from 'components/pages/PricingPage';

const Pricing = ({ data }) => (
  <Layout>
    <Helmet title="Pricing" />

    <PricingPage content={data.allPricingJson.nodes[0]} />
  </Layout>
);

export const query = graphql`
  {
    allPricingJson {
      nodes {
        plans {
          backgroundColor
          buttonLabel
          headerTitle
          id
          planCostTitle
          planCostSubtitle
          planDescription
          url
          planFeatures {
            soon
            included
            text
          }
        }
      }
    }
  }
`;

export default Pricing;
